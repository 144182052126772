.invoice-container {
  position: relative;
  margin: 2% 14%;
  background-color: #000;
  color: #fff;
  padding: 1% 2%;
}

.invoice-button {
  right: 16%;
  position: absolute;
  padding: 0.4% 3%;
  cursor: pointer;
  top: 28%;
  z-index: 1;
  background: #000;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
}

/* heading */

h1 {
  font: bold 100% sans-serif;
  letter-spacing: 0.5em;
  text-align: center;
  text-transform: uppercase;
}

/* table */

table {
  font-size: 75%;
  table-layout: fixed;
  width: 100%;
}

table {
  border-collapse: separate;
  border-spacing: 2px;
}

th,
td {
  border-width: 1px;
  padding: 0.5em;
  position: relative;
  text-align: left;
  color: #000;
  border-radius: 0.25em;
  border-style: solid;  
  word-wrap: break-word;
}

th {
  background: #eee;
  border-color: #bbb;
}

td {
  border-color: #ddd;
  color: #fff;
  text-align: end;
}

/* header */

header {
  margin: 0 0 3em;
}

header:after {
  clear: both;
  content: "";
  display: table;
}

header h1 {
  background: #000;
  border-radius: 0.25em;
  color: #fff;
  margin: 0 0 1em;
  padding: 0.5em 0;
}

header address {
  float: left;
  font-size: 75%;
  font-style: normal;
  line-height: 1.25;
  margin: 0 1em 1em 0;
}

header address p {
  margin: 0 0 0.25em;
}

header span,
header img {
  display: block;
  float: right;
}

header span {
  margin: 0 0 1em 1em;
  max-height: 25%;
  max-width: 60%;
  position: relative;
}

header img {
  max-height: 100%;
  max-width: 100%;
}

header input {
  cursor: pointer;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* article */

article,
article address,
table.meta,
table.inventory {
  margin: 0 0 3em;
}

article:after {
  clear: both;
  content: "";
  display: table;
}

article h1 {
  clip: rect(0 0 0 0);
  position: absolute;
}

article address {
  float: left;
  font-size: 125%;
  font-weight: bold;
}

/* table meta & balance */

table.meta,
table.balance {
  float: right;
  width: 36%;
}

table.meta:after,
table.balance:after {
  clear: both;
  content: "";
  display: table;
}

/* table meta */

table.meta th {
  width: 40%;
}

table.meta td {
  width: 60%;
}

/* table items */

table.inventory {
  clear: both;
  width: 100%;
}

table.inventory th {
  font-weight: bold;
  text-align: center;
}

table.inventory td {
  text-align: center;
}

table.inventory td:nth-child(1) {
  width: 26%;
}

table.inventory td:nth-child(2) {
  width: 38%;
}

table.inventory td:nth-child(3) {
  width: 12%;
}

table.inventory td:nth-child(4) {
  width: 12%;
}

table.inventory td:nth-child(5) {
  width: 12%;
}

/* table balance */

table.balance th,
table.balance td {
  width: 50%;
}

table.balance td {
  text-align: right;
}

/* aside */

aside h1 {
  border: none;
  border-width: 0 0 1px;
  margin: 0 0 1em;
}

aside h1 {
  border-color: #999;
  border-bottom-style: solid;
}

aside p {
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .invoice-container {
    margin: 3%;
  }

  .invoice-button {
    top: 10%;
    right: 4%;
  }
}

.invoiceAddress {
  width: 50%;
  white-space: pre-line;
}

@media screen and (max-width: 680px) {
  .invoice-button {
    top: 96px;
    padding: 1% 2%;
  }

  .invoiceAddress {
    width: 80%;
  }
}

