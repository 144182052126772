.Profile-main{
    display: flex;
    justify-content: space-evenly;
    padding-top: 10px;
}
.Profile-details{
    width:35%;
}
.Profile-upgrade{
    width: 40%;
    padding-bottom: 60px;
}
.Profile-heading-info{
    font-family: Segoe UI;
    font-size: clamp(14px, 1.4vw, 32px);
    font-weight: 600;

}
.Profile-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.Profile-heading-info-last{
    font-family: Segoe UI;
    font-size: clamp(10px, 1vw, 25px);
    color: #FF0000;
}
.Profile-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}
.Profile-image-and-name{
    display: flex;
    align-items: center;
    font-family: Segoe UI;
    font-size: clamp(14px, 1.4vw, 32px);
    font-weight: 600;
    width: 100%;
}
.Profile-img-box{
    background: #FFFFFF17 0% 0% no-repeat padding-box;
    border: 0.5px solid #707070; 
    padding: 20px;
    margin-bottom: 50px;
}
.Profile-img-box-1{
    background: #FFFFFF17 0% 0% no-repeat padding-box;
    border: 0.5px solid #707070; 
    padding: 20px;
    position: relative;
    top: 10px;
    margin-bottom: 50px;
}
.Profile-image{
    border-radius: 20px;
}
.input-box{
    font-family: Segoe UI;
    font-size: clamp(14px, 1.4vw, 32px);
    margin-top: 10px;
}
.Profile-edit{
    font-family: Segoe UI;
    font-size: clamp(12px, 1vw, 25px);
    letter-spacing: 0px;
    color: #6BCBE1;
    border: none;
    background: none;
    cursor: pointer;
    width: 100%;
    text-align: right;
}
.Profile-name{
    font-family: Segoe UI;
    font-size: clamp(14px, 1.4vw, 32px);
    padding-left: 8%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.addressTitle {
    font-family: Segoe UI;
    font-size: clamp(14px, 1.4vw, 32px);
    padding-left: 8%;
    width: 100%;
}
.submit-btn {
    border-radius: 10px;
    font-family: Segoe UI;
    font-size: clamp(12px, 1vw, 25px);
    font-weight: 600;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 4px 16px;
}
.close-btn {
    border-radius: 10px;
    font-family: Segoe UI;
    font-size: clamp(12px, 1vw, 25px);
    margin-left: 10px;
    font-weight: 600;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 4px 16px;
} 
.text-1 {
    margin-top: 20px; 
    display: flex;
    justify-content: space-between;
}
.Mobile-number-1{
    font-family: Segoe UI;
    font-size: clamp(12px, 1vw, 25px);
    background-color: #00000029;
    outline: none;
    border: none;
    color: #fff;
    width: 70%;
    border-radius: 25px;
    height: 28px;
    border: 0.5px solid #707070;
    padding: 0 12px;
}
.Mobile-number-1:disabled{
    border: 0.5px solid #70707052;
}
.stateListView {
    width: 76%;
    max-height: 30px;
}
.stateListView option {
    background-color: #fff;
    color: #000;
}
.btn-menu{
    margin-left: 54%;
    margin-top: 20px;
}
.Phone-number-text {
    padding-left:20px;
    padding-right: 20px;
    color: #F79420;
    font-family: Segoe UI;
    font-size: clamp(12px, 1vw, 25px);
    text-wrap: nowrap;
}
.Mobile-number{
    font-family: Segoe UI;
    font-size: clamp(12px, 1vw, 25px);
}
.Profile-phonenumber {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color:#00000029 ;
    border-radius: 25px;
   }
.Profile-second-box{
    box-shadow: 0px 3px 6px #00000029;
    border: 0.20000000298023224px solid #F2F2F2;
    border-radius: 11px;
    padding: 10px;
}
.Profile-advance{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}
.Profile-advance-image{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4% 0 2% 4%;
}
.Profile-advanced-text{
    font-family: Segoe UI;
    font-size: clamp(16px, 1.4vw, 30px);
    font-weight: bold;
    padding-left: 10%;
}
.Profile-upgrade-btn{
    background: transparent linear-gradient(180deg, #DC1C5040 0%, #F7942040 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #DC1C50;
    border-radius: 9px;
    padding-top: 5px;
    padding-right: 15px;
    padding-bottom: 5px;
    padding-left: 15px;
    text-align: center;
    color: white;
    cursor: pointer;
    font-size: clamp(12px, 1vw, 22px);
    text-decoration: none;
}
.profile-subscription-icon {
    width: 20%;
}
.Profile-list{
    list-style: none;
    margin: initial;
    padding: unset;
    padding-left: 35px;
    padding-bottom: 10px;
    font-family: Segoe UI;
    font-size: clamp(11px, 1vw, 22px);
}
.Profile-list-point-data{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Segoe UI;
    font-size: clamp(12px, 1vw, 28px);
    margin: 1% 0;
}
.Profile-list-point{
    width: 0;
    height: 0;
    margin-right: 2%;
    background-color: #DC1C50;
    padding: 0.5%;
    border-radius: 50%;
    display: block;
} 
.Profile-Payment{
    color: #04BDE8;
    cursor: pointer;
    font-family: Segoe UI;
    font-size: clamp(14px, 1vw, 25px);
    padding-left: 20px;
    border: none;
    background: none;
    padding-bottom: 15px;
}
.Amount{
    color: #FDE100;
}
.edit-user-error {
    color: #e74e4e;
    font-size: clamp(6px, 1vw, 28px);
    margin: 10px 20px;
}
.printable-table td {
    background: #000;
}
.Profile-email{
    font-family: Segoe UI;
    font-size: clamp(12px, 0.8vw, 24px);
    padding-left: 8%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.profile-edit-box{
    display: flex;
    flex-direction: column;
}
@media screen and (max-width: 720px) {
    .Profile-main {
      flex-direction: column;
    }
    .Profile-main {
      margin: 2% 6%;
    }
    .Profile-details{
        width: 100%;
    }
    .Profile-upgrade{
        width: 100%;
    }
    .Payment-header{
        padding-right: 30px;
        padding-left: 20px;
    }
}
@media screen and (max-width: 1024px) {
    .btn-menu {
        margin-left: 32%;
    }
    .Mobile-number-1{
        width: 56%;
    }
    .Type{
        overflow-wrap: anywhere;
    }
}