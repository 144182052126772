.checkbox-container{
  margin: 6% 0 8% 0;
}
.login-modal-container {
  position: relative;
  width: 60%;
}
.login-modal-container .login-modal-background-image {
  width: 100%;
}
.login-container {
  position: absolute;
  left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 93%;
}
.login-title {
  font-size: clamp(18px, 2.4vw, 42px);
  font-family: Segoe UI;
  font-weight: bold;
  letter-spacing: 0px;
  color: #f79420;
  padding-bottom: 4%;
}
.login-desc {
  font-family: Segoe UI;
  font-size: clamp(10px, 1.4vw, 30px);
  font-weight: 400;
}
.login-input-container {
  background: #ffffff46 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 3% 6%;
  margin: 6% 0 2% 0;
}
.login-input {
  outline: none;
  border: none;
  background-color: #ffffff00;
  color: #fff;
  font-size: clamp(10px, 1.3vw, 24px);
  font-family: Segoe UI;
  width: 100%;
}
.login-input::placeholder {
  font-family: Segoe UI;
  font-size: clamp(8px, 1.2vw, 18px);
}
.login-input-container .login-input-icon {
  width: clamp(8px, 1vw, 20px);
}
.login-number-error {
  color: #e74e4e;
  font-size: clamp(6px, 1vw, 28px);
  margin-top: -2%;
  margin-bottom: 2%;
}
.login-input-button {
  background: #dc1c50 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  border: none;
  color: #fff;
  padding: 1% 10% 2% 10%;
  font-family: Segoe UI;
  font-weight: 600;
  font-size: clamp(10px, 1.4vw, 24px);
  cursor: pointer;
}
.login-input-otp-container {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10%;
  margin-left: 0px;
  margin-bottom: 0px;
}
.login-otp-error {
  color: #e74e4e;
  font-size: clamp(6px, 1vw, 28px);
  margin-top: -10%;
  /* margin-bottom: 10%; */
}
.login-otp-input {
  outline: none;
  width: clamp(10px, 1.6vw, 32px);
  background-color: transparent;
  border: 1px solid #ffffff59;
  padding: 3%;
  margin: 2% 4%;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  font-size: clamp(8px, 1.4vw, 32px);
}
.login-otp-input:first-of-type {
  margin-left: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.login-timer-view {
  margin: 0px;
  padding-bottom: 9%;
  font-size: clamp(6px, 1vw, 18px);
}
.login-timer {
  color: #4bce5d;
  margin-left: 1%;
}
.login-resend {
  font-family: Segoe UI;
  font-size: clamp(6px, 1vw, 20px);
  margin-top: 5%;
}
.login-resend-button {
  border: none;
  color: #04bde8;
  background: none;
  font-family: Segoe UI;
  font-size: clamp(6px, 1vw, 20px);
  cursor: pointer;
}
.disable-resend-button {
  cursor: auto;
  color: #ccc;
}
.country-code-dropdown {
  outline: none;
  border: none;
  background: transparent;
  color: #fff;
  font-size: clamp(10px, 1.2vw, 24px);
  text-align: center;
  padding: 0;
  margin: 0 2%;
  /* width: 50% !important; */
  -webkit-appearance: auto;
  appearance: auto;
  color: var(--baseFg);
  border: 1px solid var(--baseFg);
  line-height: 1;
  outline: 0;
  border-radius: var(--radius);
  background-color: var(--baseBg);
  background-image: linear-gradient(var(--baseFg), var(--baseFg)),
    linear-gradient(-135deg, transparent 50%, var(--accentBg) 50%),
    linear-gradient(-225deg, transparent 50%, var(--accentBg) 50%),
    linear-gradient(var(--accentBg) 42%, var(--accentFg) 42%);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 1px 100%, 20px 22px, 20px 22px, 20px 100%;
  background-position: right 20px center, right bottom, right bottom,
    right bottom;
}
.country-code-dropdown * {
  font-size: clamp(10px, 1.2vw, 12px);
  color: #000;
  min-height: 0.5rem !important;
  padding: 0 !important;
  line-height: 0;
  margin: 0 !important;
}
.country-code-dropdown:hover {
  background-image: linear-gradient(var(--accentFg), var(--accentFg)),
    linear-gradient(-135deg, transparent 50%, var(--accentFg) 50%),
    linear-gradient(-225deg, transparent 50%, var(--accentFg) 50%),
    linear-gradient(var(--accentFg) 42%, var(--accentBg) 42%);
}
.country-code-dropdown:active {
  border-color: var(--accentFg);
  background-color: var(--accentFg);
}
.sample-login-background {
  width: 60vw;
  height: calc(100vw * 0.32);
  background-color: #363636;
  border-radius: 24px;
}
.hide-login-img-visibility {
  display: none;
}
@media screen and (min-width: 1025px) {
  .login-input-container {
    padding: 2% 6%;
    margin: 6% 0 8% 0;
  }
  .login-input-error {
    margin-top: -8%;
    margin-bottom: 8%;
  }
  .login-input-button {
    padding: 1% 10% 3% 10%;
  }
  .login-input-otp-container {
    /* margin: 12%; */
    margin-left: 0;
  }
  .login-otp-error {
    color: #e74e4e;
    font-size: clamp(6px, 1vw, 28px);
    margin-top: -12%;
    margin-bottom: 12%;
  }
}
@media screen and (max-width: 720px) {
  .login-modal-container {
    width: 80%;
  }
  .login-container {
    height: 100%;
  }
  .login-input-container {
    padding: 2% 6%;
    margin: 6% 0 8% 0;
  }
  .login-input-otp-container {
    margin-top: 5%;
    margin-bottom: 2%;
  }
  .login-timer-view {
    padding-bottom: 4%;
  }
  .login-resend {
    margin-top: 3%;
  }
}

