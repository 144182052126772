.Payment-main{
    padding-bottom: 40px;
}
.Payment-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 25px;
}
.Paymemt-subhead{
    font-family: Segoe UI;
    font-size: clamp(13px, 1.2vw, 25px);
    cursor: pointer;
    padding-right: clamp(5px, 1.2vw, 20px);
    border: none;
    background: none;
    color: white;
}
.Payment-history{
    font-family: Segoe UI;
    font-size:clamp(13px, 1.2vw, 25px);
    color: #F79420 ;
    padding-left: clamp(5px, 1.2vw, 20px);
}
.Payment-print{
    color: #6BCBE1;
    font-family: Segoe UI;
    font-size: clamp(13px, 1.2vw, 20px);
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}
.scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 300px;
    width: 88%;
    margin: 10px 5px;
    padding: 5px;
    text-align: center;
}
.scroll::-webkit-scrollbar {
    width:5px;
}
.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius:5px;
}
.scroll::-webkit-scrollbar-thumb {
    border-radius:5px;
    -webkit-box-shadow: inset 0 0 6px #505050; 
}
.Payment-Table{
    width: 100%;
    border: 0.5px solid #707070;
    text-align: center;
    font-family: Segoe UI;
    font-size: clamp(10px, 1.2vw, 25px);
    background: #FFFFFF17 0% 0% no-repeat padding-box;
    border-collapse:collapse;
}
.Payment-Table thead tr td{
    font-weight: 700;
}
.Payment-Table td {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}
.Payment-Status-success{
    color: #4BCE5D;
}
.Payment-Status-failed{
    color: #DC1C50;
}
.subsctiptionId{
    text-decoration: none;
    color: #fff;
}
.subsctiptionId:hover{
    color: blue;
}

@media screen and (max-width: 720px) {
    .Payment-header{
        padding-right: 30px;
        padding-left: 20px;
    }
}
@media screen and (max-width: 450px) {
    .payment-table-align{
        overflow-wrap: anywhere;
    }
}
@-moz-document url-prefix() {
    @media screen and (max-width: 1024px) {
        .Payment-Date {
            overflow-wrap: break-word;
        }
    }
}