.modal-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.67);
  z-index: 9999;
}
.modal-close-container {
  display: flex;
  justify-content: flex-end;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1000;
  position: absolute;
  top: 6%;
  right: 4%;
}
.modal-close-icon {
  width: clamp(8px, 1.6vw, 24px);
}

