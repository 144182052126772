.footer-main-container {
  display: flex;
  justify-content: space-between;
  padding: 4% 6%;
  font-family: Segoe UI;
  font-size: clamp(11px, 1vw, 25px);
  background: transparent linear-gradient(180deg, #6bcbe130 0%, #dc1c5030 100%)
    0% 0% no-repeat padding-box;
}
.footer-left{
  margin-top: clamp(10px, 1vw, 20px);
}
.footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: clamp(10px, 1vw, 20px);
  margin-top: clamp(20px, 1vw, 20px);
}
.button-view {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
.android-app-button,
.apple-app-button {
  padding: 0;
}
.android-app,
.apple-app{
  height: clamp(40px, 3.5vw, 90px);
}
.footer-social-media {
  display: flex;
  align-items: center;
  margin-bottom: 5%;
}
.footer-social-media .fb-btn {
  margin-right: 6px;
  border-radius: 50%;
  padding: 8px 14px;
}
.footer-social-media .insta-btn {
  margin-right: 6px;
  border-radius: 50%;
  padding: 7px 9px;
}
.footer-social-media .twitter-btn {
  margin-right: 6px;
  border-radius: 50%;
  padding: 7px 9px;
}
.footer-social-media .youtube-btn {
  margin-right: 6px;
  border-radius: 50%;
  padding: 11px 10px;
}
.footer-social-media .linkedin-btn {
  border-radius: 50%;
  padding: 10px 12px;
}
.footer-social-media a {
  background-color: #ffffff;
  border-radius: 10px;
  background-size: cover;
  padding: 10px;
  cursor: pointer;
}
.footer-links {
    margin-left: clamp(10px, 1.2vw, 20px);
    margin-bottom: clamp(10px, 1.2vw, 20px);
}
.footer-links a {
  text-decoration: none;
  color: rgb(252, 251, 251);
  margin: 0 4px;
}
.footer-links a:hover {
  color: #f79420;
}
.footer-email-subscribe {
  display: flex;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
  padding: 2px 4px;
  margin: 22px 8px;
}
.footer-email-subscribe-btn {
  font-family: Segoe UI;
  font-size: clamp(12px, 1vw, 22px);
  color: white;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 9px;
  height: 32px;
  cursor: pointer;
}
.footer-email-subscribe-input {
  outline: none;
  border: none;
  background-color: #ffffff00;
  font-size: clamp(13px, 1vw, 22px);
}
.footer-register {
   margin-left: clamp(10px, 1.2vw, 20px);
}
.footer-register-mobile {
  display: none;
}

@media screen and (max-width: 720px) {
  .footer-main-container {
    display: block;
    padding: 8% 4%;
  }
  .footer-register {
    display: none;
  }
  .footer-register-mobile {
    display: block;
    margin-left: 6px;
  }
  .footer-right {
    align-items: flex-start;
  }
  .footer-left-apps{
    display: flex;
  }
}

@media screen and (max-width: 1024px) {
  .footer-email-subscribe {
    margin: 16px 0;
    padding: 0 4px;
    border-radius: 4px;
  }
}

@media screen and (min-width: 720px) and (max-width: 1024px) {
  .footer-left {
    width: 50%;
  }
}
@-moz-document url-prefix() {
  @media screen and (max-width: 360px) {
    .footer-links a {
      text-decoration: none;
      color: rgb(252, 251, 251);
      margin: 0 2px;
    }
  }
  @media screen and (width: 768px) {
    .footer-links a {
      text-decoration: none;
      color: rgb(252, 251, 251);
      margin: 0 3px;
    }
  }
}
