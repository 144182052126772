.corousal-view-container {
  padding-left: 58px;
}
.corousal-view-container img {
  width: 100%;
  border-radius: 4px;
}
.corousal-name {
  padding: 1% 0;
  font: normal normal 600 1.6vw Segoe UI;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  right: 1%;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  left: 1%;
}
.react-multiple-carousel__arrow {
  top: 4px;
  height: 92%;
}
.corousal-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 58px;
}
.corousal-image-view-container {
  margin: 0 6px;
}

@media screen and (max-width: 1600px) {
  .action-btn {
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .corousal-playlistadd-btn {
    width: clamp(12px, 2vw, 22px);
  }
  .corousal-favourite-btn {
    width: clamp(12px, 2vw, 22px);
  }
  .corousal-share-btn {
    width: clamp(12px, 2vw, 22px);
  }
}

@media screen and (max-width: 1024px) {
  .corousal-view-container {
    padding-left: 12px;
  }
  /* .corousal-image-actions {
    display: none;
  } */
  .corousal-name {
    padding: 1%;
    font: normal normal 600 2.4vw Segoe UI;
  }
}
@media screen and (max-width: 720px) {
  .corousal-name {
    padding: 1%;
    font: normal normal 600 2.6vw Segoe UI;
  }
}
@media screen and (max-width: 464px) {
  .corousal-name {
    padding: 1.2%;
    font: normal normal 600 3.4vw Segoe UI;
  }
}
