.main-container {
  display: flex;
  flex-direction: column;
  background-color: #000;
  color: #fff;
  /* min-height: 100vh; */
  justify-content: space-between;
  min-height: 100vh;
}
.page-view-container {
  margin-top: 77px;
  min-height: 900px;
}
.page-view-container.with-sub-menu {
  margin-top: 120px;
}

@media screen and (max-width: 1024px) {
  .page-view-container, .page-view-container.with-sub-menu {
    margin-top: 78px;
  }
}
