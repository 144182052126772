.games-page-container {
  margin: 2% 6%;
}
.games-page-header {
  font-family: Segoe UI;
  font-weight: 600;
  font-size: clamp(14px, 2.2vw, 52px);
  margin-bottom: 2%;
}
.games-page-img-container {
  width: 100%;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(12vw, 1fr));
  padding-top: 15px;
}
.games-page-container .corousal-image-view {
  width: 12vw;
  padding-bottom: 15px;
  margin: 0;
  overflow: hidden;
}
.games-page-container .corousal-image {
  width: 12vw;
}
.games-page-no-data {
  text-align: center;
  font-family: Segoe UI;
  font-weight: 600;
  font-size: clamp(12px, 1.8vw, 46px);
}
.loading-image {
  width: 11.3vw;
  height: calc(100vw * 0.15);
  margin: 0;
}

@media screen and (max-width: 1024px) {
    .loading-image {
      width: 19vw;
      height: calc(100vw * 0.257);
    }
}

@media screen and (max-width: 720px) {
    .games-page-img-container {
      grid-template-columns: repeat(auto-fill, minmax(24vw, 1fr));
    }
    .games-page-container .corousal-image-view {
      width: 24vw;
      padding: 0;
      margin: 0;
    }
    .games-page-container .corousal-image {
      width: 24vw;
    }
    .loading-image {
      width: 18.8vw;
      height: calc(100vw * 0.253);
    }
}

@media screen and (max-width: 480px) {
    .loading-image {
      width: 30.2vw;
      height: calc(100vw * 0.4);
    }
}
