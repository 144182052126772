.home-page-container {
}
.home-page-container img {
  width: 100%;
}
.banner-actions-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgb(0 0 0 / 80%) 36%, #333333a8 50%, rgba(255,255,255,0) 65%);
}
.banner-desc {
  position: relative;
  left: 14%;
  color: white;
  font-family: Segoe UI;
  font-size: clamp(12px, 1.8vw, 36px);
  font-weight: 400;
}
.banner-play-button{
  position: relative;
    left: 14%;
    top: 8%;
    background: #f23266;
    border-radius: 6px;
    text-align: left;
    color: #ffffff;
    padding: 0.5% 2%;
    cursor: pointer;
    outline: none;
    border: none;
    font-family: Segoe UI;
    font-size: clamp(12px, 1.4vw, 26px);
}
.Nav-menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  border-bottom: 2px solid #000;
}
.Nav-menu-container a {
  padding: 0 12px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
}
.Main-container {
  padding: 14px;
}
.home-page-data-container {
  padding: 24px 0;
}
.main-corousal-container .react-multi-carousel-dot-list {
  bottom: 8px;
}
.react-multi-carousel-list.main-corousal-container
  .react-multi-carousel-dot-list
  .react-multi-carousel-dot
  button {
  border-radius: 4px;
  width: 8vw;
  height: auto;
  padding: calc(100vw * 0.001);
}
.sample-banner {
  width: 100%;
  height: calc(100vw * 0.28);
  background-color: #363636;
}
.hide-visibility {
  visibility: hidden;
  display: none;
}

@media screen and (max-width: 1024px) {
  .react-multi-carousel-list.main-corousal-container
    .react-multi-carousel-dot-list
    .react-multi-carousel-dot
    button {
    border-radius: 4px;
    width: 8vw;
    height: auto;
    padding: calc(100vw * 0.002);
  }
}
