.game-detail-page-container {
  padding: 2% 5%;
}
.game-detail-page-container .loading-image {
  width: 10.7vw;
  height: calc(100vw * 0.142);
}
.game-detail-page-breadcrump {
  margin-bottom: 2%;
  font-family: Segoe UI;
  font-size: clamp(8px, 1.2vw, 28px);
}
.game-detail-page-breadcrump a {
  color: #fff;
  text-decoration: none;
}
.game-detail-page-breadcrump .game-detail-name {
  color: #f79420;
}
.game-detail-page-banner {
  position: relative;
}
.game-detail-page-banner img {
  width: 100%;
}
.game-detail-banner-actions-container {
  position: absolute;
  top: 0;
  width: 90%;
  height: -webkit-fill-available;
  padding: 2% 4%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent linear-gradient(90deg, #101010 0%, #ffffff00 100%) 0%
    0% no-repeat padding-box;
}
.game-detail-banner-title {
  font-family: Segoe UI;
  font-weight: 600;
  font-size: clamp(14px, 2.6vw, 48px);
}
.game-detail-banner-source {
  font-family: Segoe UI;
  font-weight: 600;
  font-size: clamp(8px, 1.2vw, 28px);
  margin: 12px 0;
  color: #ffffffd0;
}
.game-detail-banner-desc {
  font-family: Segoe UI;
  font-size: clamp(10px, 1.2vw, 34px);
  color: #fff;
  padding-top: 6px;
}
.game-detail-banner-actions {
  display: flex;
  align-items: center;
  /* width: clamp(25%, 25vw, 45%); */
  width: 70%;
  justify-content: space-between;
}
.game-detail-banner-actions .game-action {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-family: Segoe UI;
  font-size: clamp(8px, 1.4vw, 36px);
  font-weight: 600;
}
.game-detail-banner-play-actions img {
  width: clamp(10px, 2vw, 42px);
  margin-right: 4px;
}
.game-detail-banner-playlist-actions img {
  width: clamp(10px, 2vw, 42px);
  margin-right: 8px;
}
.game-detail-banner-share-actions img {
  width: clamp(12px, 1.4vw, 38px);
  margin-right: 10px;
}
.game-detail-reviews {
  margin: 32px 0;
}
.game-detail-reviews-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.game-detail-reviews-title {
  font-family: Segoe UI;
  font-weight: 600;
  font-size: clamp(10px, 1.8vw, 38px);
}
.game-detail-reviews-count {
  font-family: Segoe UI;
  font-size: clamp(6px, 1vw, 26px);
}
.game-detail-reviews-more {
  color: #04bde8;
}
.game-detail-user-review {
  background: #ffffff17 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  padding: 1% 2%;
  margin-bottom: 8px;
}
.game-detail-review-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.game-detail-review-user-main {
  display: flex;
  align-items: center;
}
.game-detail-review-user-main img {
  border-radius: 50%;
  width: clamp(18px, 3vw, 48px);
}
.game-detail-review-user-name {
  padding-left: 16px;
  font-family: Segoe UI;
  font-weight: 600;
  font-size: clamp(10px, 1.2vw, 28px);
}
.game-detail-review-user-date {
  font-family: Segoe UI;
  font-weight: 600;
  font-size: clamp(6px, 0.8vw, 22px);
  letter-spacing: 0px;
  color: #c2c2c2;
}
.game-detail-review-comment {
  padding-top: 16px;
  font-family: Segoe UI;
  font-size: clamp(8px, 1.2vw, 32px);
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}
.game-detail-review-delete-icon {
  margin-left: 2%;
  width: 2%;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  padding-right: 0;
}
.game-detail-review-delete-icon img {
  width: 100%;
}
.game-detail-page-container .corousal-view-container {
  padding: 0;
  margin-top: 24px;
}
.game-detail-review-user-rating {
  display: flex;
  align-items: center;
}
.game-detail-review-star {
  margin-right: 15px;
}
.game-review-button {
  width: 100%;
  padding: clamp(6px, 12px, 18px);
  font-family: Segoe UI;
  font-weight: 600;
  font-size: clamp(12px, 1.5vw, 26px);
  cursor: pointer;
}
.game-review-input-container {
  display: grid;
  gap: 12px;
  padding: 0 30%;
}
.game-review-input {
  border-radius: 6px;
  font-size: clamp(12px, 1vw, 26px);
  font-family: Segoe UI;
}
.rating-star-view {
  font-family: Segoe UI;
  font-weight: 600;
  font-size: clamp(11px, 1.4vw, 26px);
}
.game-review-add-button {
  cursor: pointer;
  padding: clamp(4px, 6px, 10px);
  font-family: Segoe UI;
  font-weight: 600;
  font-size: clamp(8px, 1.2vw, 22px);
}
.reviews-no-data {
  text-align: center;
  font-family: Segoe UI;
  font-weight: 600;
  font-size: clamp(10px, 1.2vw, 34px);
}
.review-error {
  color: #e74e4e;
  font-size: clamp(6px, 1vw, 28px);
}
.game-play-container {
  display: flex;
  margin: 1%;
  position: relative;
}
iframe {
  display: block;
  height: 80vh;
  width: 100vw;
  border: none;
}
.game-play-container.game-full-screen iframe {
  height: 100vh;
}
.game-full-screen{
  display: flex;
  flex-direction: column-reverse;
}
.game-play-actions-fullscreen{
  display: flex;
  justify-content: flex-end;
}
.game-play-actions {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  margin: 1% 0;
}
.game-play-button {
  background: #000;
  outline: none;
  border: none;
  cursor: pointer;
}
.play-button-close {
  /* width: 30% */
}
.play-button-fullscreen img{
  width: 30%;
}
.play-button-replay img{
  width: 30%;
}
.game-detail-banner-descrption{
  font-family: Segoe UI;
  font-weight: 600;
  font-size: clamp(10px, 1.8vw, 34px);
  color: #fff;
  padding-top: 20px;
}
.hide-image {
  display: none;
}
.loading-image {
  width: 87vw;
  height: calc(100vw * 0.155);
  margin: 0;
  background-color: #363636;
  border-radius: 4px;
}
@media screen and (max-width: 1024px) {
  .game-detail-banner-actions {
    width: 100%;
  }
  .game-action:first-of-type {
    padding-left: 0;
  }
  .game-detail-banner-source {
    margin: 12px 0;
  }
  .game-play-actions {
    flex-direction: row;
    justify-content: flex-end;
    margin: 0;
  }
  iframe {
    height: 65vh;
  }
  .play-button-close {
    width: 16%;
  }
  .play-button-fullscreen {
    width: 16%;
  }
  .play-button-fullscreen img {
    width: 100%;
  }
  .play-button-replay {
    width: 16%;
  }
  .play-button-replay img {
    width: 100%;
  }
  .game-detail-page-container .loading-image {
    width: 17.2vw;
    height: calc(100vw * 0.232);
  }
}
@media screen and (max-width: 720px) {
  .game-review-input-container {
    padding: 0;
  }
  .game-detail-page-breadcrump {
    margin-bottom: 12px;
  }
  .game-detail-banner-source {
    margin: 6px 0;
  }
  .game-detail-banner-play-actions img {
    margin-right: 4px;
  }
  .game-detail-banner-playlist-actions img {
    margin-right: 8px;
  }
  .game-detail-banner-share-actions img {
    margin-right: 8px;
  }
  .game-detail-review-delete-icon {
    width: 3.5%;
  }
  .game-detail-page-container .loading-image {
    width: 17.2vw;
    height: calc(100vw * 0.232);
  }
}
@media screen and (max-width: 480px) {
  .game-detail-review-delete-icon {
    width: 5%;
  }
  .game-detail-page-container .loading-image {
    width: 28.4vw;
    height: calc(100vw * 0.386);
  }
}
@media screen and (max-width: 321px) {
  .game-detail-review-delete-icon {
    width: 6%;
  }
}
@-moz-document url-prefix() {
  .game-detail-banner-actions-container {
    height: 85%;    
  }
}
.game-details-notaccessable-popup{
  position: fixed;
  top:0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1003;
  width: 100%;
  height: 100vh;
}
.game-notaccessable-popup-view{
  background-color: #fff;
  border: 2px solid #000;
  color: #000;
  width : 35%;
  padding-bottom: 1%;
  border-radius: 8px;
}
.game-access-message{
  text-align: center;
  font-size: clamp(15px, 1.2vw, 20px);
  font-family: Segoe UI;
  padding: 4%;
  font-weight: 500;
}
.game-access-button{
  display: flex;
  justify-content: space-evenly;
  margin: 7px 0px 7px 0px;
}
.game-access-yes-button{
  background: #f23266 0% 0% no-repeat padding-box;
  text-align: left;
  font: Segoe UI;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
  padding: 5px 15px 5px 15px;
  font-size: clamp(15px, 1.2vw,20px);
  border-radius: 10px;
  border: none;
}
.game-access-no-button{
  background: #04bde8 0% 0% no-repeat padding-box;
  text-align: left;
  font: Segoe UI;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
  padding: 5px 15px 5px 15px;
  font-size: clamp(15px, 1.2vw, 20px);
  border-radius: 10px;
  border: none;
}
@media screen and (width:540px) {
  iframe{
    height: 74vh;
  } 
}
@media screen and (max-width: 760px) {
  .game-details-notaccessable-popup {
    align-items: baseline;
    top: 18%;
  }
  .game-notaccessable-popup-view {
    width: 80%;
    height: max-content;
  }
}
@media screen and (min-width:540px) and (max-width:912px) {
  .game-play-actions-fullscreen .play-button-close img{
    height: 85%;
  }
  .game-play-actions-fullscreen .play-button-fullscreen{
    width: 13%
  }
  .game-play-actions-fullscreen .play-button-replay{
    width: 13%;
  }
}
@media screen and (orientation: landscape) {
  .game-full-screen{
    display: flex;
    flex-direction: row;
  }
  .game-play-actions-fullscreen{
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .game-play-actions-fullscreen .play-button-close{
    width: 100%;
  }
  .game-play-actions-fullscreen .play-button-fullscreen{
    width: 85%;
  }
  .game-play-actions-fullscreen .play-button-replay{
    width: 85%;
  }
  @media screen and (min-width:667px) and (max-width:720px) {
    .game-play-actions{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .game-play-actions .play-button-close{
      width: 33%;
    }
    .game-play-actions .play-button-fullscreen{
      width: 33%
    }
    .game-play-actions .play-button-replay{
      width: 33%;
    }
  }
  @media screen and (width:667px) {
    .game-play-actions{
      width: 45%;
    }  
  }
  
  @media screen and (width:1180px) {
    .game-play-actions{
      width: 10%;
    }  
  }
}

/* @media screen and (orientation: landscape) {
  @media screen and (width :720px) {
    .play-button-close{
      width: 33%;
    }
    .game-play-actions{
      flex-direction: column;
      align-items: center;
    }
    
  }
  .game-play-actions {
    display: flex;
    flex-direction: column;
  }
  .play-button-fullscreen{
    width: 33%;
  }
  .play-button-replay{
    width: 33%;
  }
  @media screen and (width:1280px) {
    .play-button-replay{
      width: 100%;
    }
    .play-button-fullscreen{
      width: 100%;
    }
  }
  @media screen and (width:1368px) {
    .play-button-replay{
      width: 100%;
    }
    .play-button-fullscreen{
      width: 100%;
    }  
  }
  @media screen and (width:1180px) {
    .game-play-actions{
      align-items: center;
    }
    .play-button-replay{
      width: 86%;
    }
    .play-button-fullscreen{
      width: 86%;
    }
  }
} */