.subscribe-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}
.current-plan-container {
  background-color: #dc1c50;
  width: 252px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0px 0px 9px 9px;
}
.current-plan-title {
  font-family: Segoe UI;
  font-size: clamp(11px, 1.2vw, 15px);
  margin: 6% 0 1% 0;
}
.current-plan-data {
  font-family: Segoe UI;
  font-size: clamp(15px, 1.4vw, 20px);
  margin-bottom: 5%;
}
.subscribe-plan-title {
  font-family: Segoe UI;
  font-size: clamp(22px, 2.4vw, 38px);
  margin-top: 35px;
  font-weight: 600;
}
.subscribe-billed-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-family: Segoe UI;
  font-size: clamp(14px, 1.4vw, 25px);
}
.subscribe-packages-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5vh;
  margin: 3% auto;
  width: 60%;
}
.subscribe-package-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 12% 8% 12% 8%;
  background: transparent linear-gradient(180deg, #f7942069 0%, #101010 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 11px;
}
.adjust-box-width {
}
.package-detail-title {
  display: flex;
  align-items: center;
}
.package-title {
  font-family: Segoe UI;
  font-weight: bold;
  font-size: clamp(18px, 1.6vw, 34px);
  margin-left: 10px;
}
.package-detail-price {
  display: flex;
  align-items: center;
  margin: 18px 0;
  color: #fde100;
  font-family: Segoe UI;
  font-weight: 600;
  font-size: clamp(15px, 1.2vw, 25px);
}
.package-detail-price div {
  margin-right: 3px;
}
.package-detail-offer-price {
  margin-right: 2px;
  display: inline-flex;
  font-size: clamp(15px, 1.2vw, 25px);
  flex-direction: column;
  align-items: flex-end;
}
.package-detail-offer-price span {
  font-size: clamp(9px, 0.7vw, 16px);
  color: #ccc;
  text-decoration: line-through;
}
.package-detail-price-div {
  margin-right: 4px;
}
.package-detail-price-div.offer-div {
  font-size: clamp(24px, 2vw, 48px);
  font-weight: 300;
}
.package-detail-description-container {
  margin: 0;
  padding: 0 10%;
  font-family: Segoe UI;
  font-size: clamp(16px, 1vw, 27px);
}
.package-purchase-button {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #dc1c50;
  border-radius: 9px;
  background: transparent;
  color: #fff;
  padding: 6px 22px;
  margin-top: 18px;
  font-family: Segoe UI;
  font-weight: 600;
  font-size: clamp(14px, 1.2vw, 20px);
  cursor: pointer;
  width: 100%;
}
.subscribe-features-container {
  background: #ffffff17 0% 0% no-repeat padding-box;
  border: 0.5px solid #707070;
  padding: 22px 0;
  margin: 24px 0;
  width: 75%;
  text-align: center;
  border-radius: 2px;
}
.subscribe-features-title {
  font-family: Segoe UI;
  font-weight: 600;
  font-size: clamp(20px, 1.4vw, 35px);
  color: #f79420;
  padding: 12px 0;
}
.subscribe-features-description {
  font-family: Segoe UI;
  font-weight: 300;
  font-size: clamp(13px, 1.2vw, 25px);
  padding: 12px 24px;
}
.subscribe-cancel-anytime {
  width: 75%;
  background: #dc1c50 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 11px;
  font-family: Segoe UI;
  font-size: clamp(13px, 1.2vw, 27px);
  color: #ffffff;
  margin: 12px 0;
  padding: 8px;
  cursor: pointer;
}
.subscribe-cancel-anytime:disabled {
  cursor: auto;
  background: #dc1c5099;
  color: #ffffff75;
}
.subscribe-package-detail-container:hover {
  transform: scale(1.09);
}
.subscribe-package-detail-container:hover .package-purchase-button {
  background: #dc1c50;
  color: white;
}
.subscribe-package-detail-container:hover .package-purchase-button:disabled {
  background: #a1002b6e;
  color: white;
  opacity: 0.2;
}
.game-details-notaccessable-popup{
  position: fixed;
  top:0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1003;
  width: 100%;
  height: 100vh;
}
.game-notaccessable-popup-view{
  background-color: #fff;
  border: 2px solid #000;
  color: #000;
  width : 35%;
  padding-bottom: 1%;
  border-radius: 8px;
}
.game-access-message{
  text-align: center;
  font-size: clamp(15px, 1.2vw, 20px);
  font-family: Segoe UI;
  padding: 4%;
  font-weight: 500;
}

@media screen and (max-width: 1024px) {  
  .subscribe-packages-container {
    grid-template-columns: repeat(auto-fill, 60vw);
    margin-bottom: 22%;
  }
  .package-detail-title img {
    width: 30%;
  }
  .package-detail-title img.package-offer-img {
    width: 22%;
  }
}
