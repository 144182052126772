.toast-message-container {
  position: fixed;
  z-index: 10000;
  background-color: #fff;
  top: 25%;
  left: 40%;
  width: 22vw;
  border-radius: 6px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.toast-message-text {
  color: #000;
  padding: 4% 10%;
  font-family: Segoe UI;
  font-size: clamp(12px, 1.6vw, 36px);
  font-weight: 400;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .toast-message-container {
    top: 6%;
    left: 34%;
    min-width: 36vw;
    min-height: 4vh;
  }
}
@media screen and (max-width: 520px) {
  .toast-message-container {
    top: 10%;
    left: 34%;
    min-width: 36vw;
    min-height: 4vh;
  }
}
