.image-loader-main {
    width: 100%;
    height: 100%;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
.image-loader-background {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #eeeeee 8%, #e2e2e2 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}
