/* Dots loader for home page games */
.main-loader-container {
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  background-color: #00000085;
}
.dots-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 32px 0;
  overflow: hidden;
}
.dots-loader {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  animation: dots-loader 3s infinite cubic-bezier(0.15, 0.6, 0.9, 0.1);
}
.dots-loader::before,
.dots-loader::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dots-loader::before {
  left: -12px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  animation: dots-loader-before 3s infinite ease-in-out;
}
.dots-loader::after {
  left: -24px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  animation: dots-loader-after 3s infinite cubic-bezier(0.4, 0, 1, 1);
}
@keyframes dots-loader {
  0% {
    left: calc(-50% - 5px);
  }
  75% {
    left: calc(50% + 105px);
  }
  100% {
    left: calc(50% + 105px);
  }
}
@keyframes dots-loader-before {
  0% {
    left: -50px;
  }
  50% {
    left: -12px;
  }
  75% {
    left: -50px;
  }
  100% {
    left: -50px;
  }
}
@keyframes dots-loader-after {
  0% {
    left: -100px;
  }
  50% {
    left: -24px;
  }
  75% {
    left: -100px;
  }
  100% {
    left: -100px;
  }
}
