/* SearchAutocomplete.css */
.search-autocomplete {
    position: relative;
  } 
  .asi {
    bottom: 10px;
    width: 300px;
    height: 35px;
    /* padding: 8px; */
    /* margin-bottom: 8px; */
  }
  
  .suggestions-list {
    width: 106%;
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;  
    background-color: #000;
    border: 1px solid #ccc;
   /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }
  
  .suggestions-list li {
    padding: 8px;
    cursor: pointer;
  }
  
  .suggestions-list li:hover {
    background-color: #403535;
  }
  .simage{
    float: left;
    margin-right: 10px;
  }
  .clearboth{
    clear: both;
  }
 