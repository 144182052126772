 .corousal-image-view {
  padding: 0 6px;
  position: relative;
  outline: none;
  border: none;
  background: transparent;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
}
.corousal-image-view:first-of-type {
  padding-left: 0;
}
.corousal-image-view:hover .corousal-image {
  /* opacity: 0.4; */
}
.corousal-image-view:hover .corousal-image-actions {
  opacity: 1;
}
.corousal-image-actions {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 96%;
  z-index: 1;
  opacity: 0;
}
.corousal-image-actions.no-actions {
  justify-content: flex-end;
  height: 94%;
}
.corousal-image-actions.no-actions .corousal-image-data {
  margin-top: 18px;
}
.corousal-play-action {
  display: inline-flex;
  justify-content: center;
}
.corousal-image-actions .corousal-play-action img {
  width: clamp(24px, 12vw, 56px);
}
.corousal-image-social-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 12px;
  margin-top: 2px;
}
.corousal-image-actions .corousal-image-social-actions img {
  margin-top: 2px;
}
.corousal-image-actions
  .corousal-image-social-actions
  .corousal-playlistadd-action {
  width: clamp(12px, 1.5vw, 24px);
}
.corousal-image-actions
  .corousal-image-social-actions
  .corousal-favourite-action {
  width: clamp(10px, 1.2vw, 22px);
}
.corousal-image-actions .corousal-image-social-actions .corousal-share-action {
  width: clamp(10px, 1.2vw, 22px);
}
.corousal-image-data {
  text-align: left;
  margin-left: 12px;
}
/* .corousal-image-data-title {
  font-family: Segoe UI;
  font-weight: 600;
  font-size: clamp(8px, 1.2vw, 36px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */


.corousal-image-data-title {
  font-family: Segoe UI;
  font-weight: 500;
  font-size: clamp(8px, 1vw, 32px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  max-width: 150px;
}
.corousal-image-data-lang {
  font-family: Segoe UI;
  font-size: clamp(8px, 1vw, 32px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* .corousal-image-planview {
  position: absolute;
  margin: 5px;
  padding: 2px 4px;
  border-radius: 10px;
  font-family: Segoe UI;
  font-size: clamp(6px, 0.8vw, 16px);
  font-weight: 500;
  border: 1px solid #ffffff69;
  box-shadow: rgb(0 0 0 / 50%) 3px 6px 6px;
} */
.corousal-image-planview {
  position: absolute;
  top: -2px;
  left: -2px;
  /* width: 43px;
  height: 18px; */
  z-index: 1;
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 1px 10px;
  font-family: Segoe UI;
  font-size: clamp(6px, 0.8vw, 16px);
  font-weight: 600;
  border: 1px solid #ffffff69;
  box-shadow: rgb(0 0 0 / 50%) 3px 6px 6px;
  border-bottom-right-radius: 10px;
  /* border-radius: 10px; */
  border-top-left-radius: 5px;
 
}
.background-masker {
  background-color: #fff;
  position: absolute;
}
.btn-divide-left {
  top: 0;
  left: 25%;
  height: 100%;
  width: 5%;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 70px;
  position: relative;
}
.corousal-loader-view {
  display: flex;
}
.corousal-loader-view .image-loader-background {
  border-radius: 12px;
  margin: 0 12px;
}
.corousal-loader-view .image-loader-background:first-of-type {
  margin-left: 6px;
}
.loading-image {
  width: 152px;
  background-color: #363636;
  border-radius: 4px;
  height: calc(100vw * 0.155);
  margin: 0;
}
.hide-image {
  display: none;
}

@media screen and (min-width: 1024px) {
  .web-game-container {
    display: block;
  }
  .mobile-game-container {
    display: none;
  }
}
@media screen and (max-width: 1025px) {
  .web-game-container {
    display: none;
  }
  .mobile-game-container {
    display: block;
  }
}
.corousal-image-view{
  padding-right: 10px
}  
.enlarged-button{
  transform: translateY(-15px);
  /* transform: scale(1.2); */
  transition: transform 0.3s ease;
} 
.gametemplate {
  padding-top: 15px !important;
  }