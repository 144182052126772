.button-view {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
.nav-bar-container {
  position: fixed;
  width: 100%;
  border-bottom: 1px solid #fff3;
  z-index: 1001;
}
.nav-bar-section {
  display: flex;
  flex-direction: row;
  align-items: center; 
}
.nav-main-container {
  padding: 0.3% 6%;
  justify-content: space-between;
  background-color: #000;
}
.nav-logo {
  cursor: pointer;
}
.nav-logo img {
  height: 72px;
}
.nav-menu-container {
  cursor: pointer;
  margin-left: 34px;
  font-family: Segoe UI;
  font-size: clamp(14px, 1.4vw, 34px);
  font-weight: 400;
}
.nav-menu-container.selected {
  color: #f79420;
}
.verticleLine {
  height: 100%;
  width: 1;
  background-color: "#909090";
}
.nav-menu-container-label:is(:hover, .selected) {
  color: #f79420;
}
.nav-menu .modal-close-container {
  top: 49px;
}
.nav-menu .modal-close-container .modal-close-icon {
  width: clamp(16px, 1.6vw, 24px);
}
.nav-main-item {
  text-decoration: none;
  color: #fff;
  font: normal normal normal 18px/20px Segoe UI;
  padding: 4px 18px;
}
.nav-menu-item {
  text-decoration: none;
  color: #fff;
  font: normal normal normal 16px/20px Segoe UI;
  display: flex;
  flex-direction: row;
}
.nav-menu-item .nav-menu-subheader {
  display: flex;
  align-items: center;
  padding-right: 15px;
  color: #fff;
  font-family: Segoe UI;
  font-size: clamp(12px, 1.2vw, 28px);
  font-weight: 400;
}
.nav-menu-subheader:is(:hover, .selected) {
  color: #f79420;
}
.nav-menu-subheader-img {
  border-radius: 50%;
  margin-right: 8px;
  width: 24px;
}
.nav-course-container {
  padding: 0.4% 14%;
  background: transparent linear-gradient(180deg, #6bcbe130 0%, #dc1c5030 100%)
    0% 0% no-repeat padding-box;
  background-color: #000;
}
.nav-action-subscribe {
  background: #f23266 0% 0% no-repeat padding-box;
  border-radius: 9px;
  text-align: left;
  font: normal normal 600 14px/18px Segoe UI;
  letter-spacing: 0px;
  color: #ffffff;
  margin: 0 32px 0 0;
  padding: 8px 22px;
  cursor: pointer;
}
.nav-action-login {
  background-color: #000;
  color: #fff;
  font: normal normal 400 16px/24px Segoe UI;
  padding: 0;
}
.nav-user-img {
  cursor: pointer;
  width: 34px;
}
.nav-login-dropdown {
  display: grid;
  grid-template-columns: 10px 80px;
  gap: 25px;
  color: white;
  font: normal normal normal 15px/22px Segoe UI;
  opacity: 1;
  font-size: 13px;
  cursor: pointer;
  padding: 3px;
  text-decoration: none;
}
.nav-logout-btn {
  outline: none;
  border: none;
  background-color: transparent;
  text-align: left;
}
.editimag {
  padding-top: 5px;
}
.search-icon {
  background-color: #fff;
  padding: 6px 10px;
  border-radius: 4px;
}
.search-input-container {
  display: none;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 10px 0 6px; */
  padding: 0 10px 0 0px;
  margin: 22px 0;
  background: #ffffff46 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  width: fit-content;
}
.search-input {
  margin: 4px 12px 4px 2px;
  background-color: #ffffff00;
  color: #fff;
  font-size: 16px;
}
.search-container {
  margin-right: 32px;
  /* height: 35px; */
}
.search-container:hover .search-icon,
.search-container.active .search-icon {
  display: none;
}
.search-container:hover .search-input-container,
.search-container.active .search-input-container {
  display: flex;
  background-color: transparent;
  border: 1px solid #ffffff9e;
}
.nav-user-drop-down {
  position: absolute;
  top: 4.5rem;
  right: 5rem;
  width: 120px;
  padding-left: 18px;
  padding-top: 10px;
  padding-right: 30px;
  padding-bottom: 35px;
  border-radius: 10px;
  background-color: #231f20;
  z-index: 99;
}
.nav-main-drop-down {
  position: absolute;
  width: fit-content;
  border-radius: 12px;
  background-color: white;
  border: 1px solid gray;
  display: flex;
  margin: 10px;
  z-index: 99;
}
.nav-main-drop-down-item {
  width: 100%;
  color: #000;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-right: 1px solid #00000059;
  padding: 8px;
}
.nav-main-drop-down-item:is(:hover, .selected) {
  color: #f79420;
}
.nav-main-drop-down-item img {
  width: 92px;
}
.wrapper {
  padding: 10px;
  display: flex;
  top: 1rem;
}
.mobile-menu-modal.modal-container{
  justify-content: flex-end;
}
/* .show-otp-modal.modal-container .modal-close-container{
  right: 23%;
} */
@media screen and (min-width: 1025px) {
  .mobile-nav-content {
    display: none;
  }
  .nav-bar-section { 
    height: 65px;
  }
}
@media screen and (max-width: 1024px) {
  .mobile-nav-content {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .search-input-container{
    width:200px;
    height:30px;
  }
  
  .search-autocomplete{
    width:195px;
  }
  .mobile-search-input-container {
    border: 1px solid #fff;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2% 3%;
    margin-right: 8%;
    margin-bottom: 9px;
    margin-left: -9%;
  }
  .mobile-search-input-container img {
    width: clamp(10px, 1.2vw, 22px);
    opacity: 0.8;
  }
  .mobile-search-input {
    background-color: #ffffff00;
    color: #fff;
    font-size: clamp(10px, 1.4vw, 28px);
    outline: none;
    border: none;
  }
  .nav-bar-mobile-menu {
    padding: 0;
  }
  .nav-menu-container {
    display: none;
  }
  .nav-action-section {
    display: none;
  }
  .nav-course-container {
    display: none;
  }
  .nav-menu {
    width: 80%;
    height: 100%;
    overflow-y: auto;
    background-color: rgb(255 255 255);
    opacity: 0.85;
    z-index: 101;
    padding: 7px 0;
    transition: all 0.5s ease;
  }
  .menu {
    padding: 12px 24px;
  }
  .menu-user-name {
    color: #000;
    padding: 12px 15px;
    font: normal normal 600 24px/32px Segoe UI;
  }
  .menu-login {
    outline: none;
    border: none;
    background-color: transparent;
    color: #000;
    padding: 12px 15px;
    font: normal normal 600 24px/32px Segoe UI;
    cursor: pointer;
  }
  .menu-item {
    display: block;
    margin: 0;
  }
  .menu > .menu-item-has-children > .menu-item-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #33333333;
    cursor: pointer;
  }
  .menu-item-name {
    color: #000;
    padding: 12px 15px;
    font: normal normal 400 22px/28px Segoe UI;
  }
  .menu-item-name > a {
    text-decoration: none;
    color: inherit;
  }
  .menu > .menu-item:first-child > .menu-item-name {
    border-top: 1px solid #333333;
  }
  .menu > .menu-item > .sub-menu {
    width: 100%;
    position: relative;
    opacity: 1;
    visibility: visible;
    border: none;
    background-color: transparent;
    box-shadow: none;
    transform: translateY(0px);
    padding: 0px;
    left: auto;
    top: auto;
    overflow: hidden;
  }
  .img-1{
    width: clamp(8px, 4.2vw, 24px);;
  }
  .menu > .menu-item > .sub-menu > .sub-menu-item-name {
    padding: 8px 42px;
    color: rgb(0 0 0 / 90%);
    border-bottom: none;
    text-decoration: none;
    background-color: #f1f1f1;
    font: normal normal normal 20px/26px Segoe UI;
    cursor: pointer;
  }
}
.search-error-msg{
  margin: 0px;
  font-size: clamp(8px, 0.8vw, 15px);
  font-weight: 400;
  font-family: Segoe UI;
  margin-top: -15px;
}
.search-error-mobile{
  margin: 0px;
  font-size: 10px;
  font-family: Segoe UI;
  font-weight: 500;
  margin-top: -7px;
}
.mobile-search{
  width: 200px;
}

@media screen and (max-width: 1024px){
.menu-user-name {
  margin-top: -25px;
}
}
@media screen and (max-width: 540px){
  .menu-user-name {
    margin-top: -20px;
  }
}
@-moz-document url-prefix() {

  @media screen and (min-width: 1024px) {
    .mobile-nav-content {
      display: none;
    }
  }
  @media screen and (max-width: 1024px) {
    .mobile-nav-content {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }
}

/* Autocomplete.css */

.autocomplete-container {
  max-width: 300px;
  margin: auto;
  padding: 20px;
}

/* Style for the autocomplete input */
.rsa-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

/* Style for the autocomplete suggestions */
.rsa-suggestions {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
}

/* Style for each suggestion item */
.rsa-suggestion {
  padding: 10px;
  cursor: pointer;
}

/* Style for the highlighted suggestion */
.rsa-suggestion-highlighted {
  background-color: #f0f0f0;
}
